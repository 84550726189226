import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, update, onValue, orderByChild, query } from "firebase/database";
import { useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MuiAlert from '@mui/material/Alert';
import { CircularProgress, Typography } from "@mui/material";
import { servicesValidate, idServicesEnabled } from "../../utils/validations";


function AddServices({ nextStep, data, servicesData }) {
    let { id } = useParams();
    // const cuadrillaSelected = JSON.parse(sessionStorage.getItem("cuadrilla"));
    const [servicesToSave, setServicesToSave] = useState([]);
    const [idOt] = useState(id);
    const [services, setServices] = useState([]);
    const [alertValidationEmbutido, setAlertValidationEmbutido] = useState('');
    const [alertValidationInstalacionOnt, setAlertValidationInstalacionOnt] = useState('');
    const [alertValidationDecoUtp, setAlertValidationDecoUtp] = useState('');
    const [alertValidationBocaRed, setAlertValidationBocaRed] = useState('');
    const [enabledIds, setEnabledIds] = useState([]);

    useEffect(() => {
        orderService(servicesData);
        // const refServicios = query(ref(database, '/servicios_new/'), orderByChild('id'));
        // onValue(refServicios, (snapshot) => {
        //     setServices(snapshot.val())
        // }, {
        //     onlyOnce: true
        // });
    }, []);

    const checkService = (e, id, name) => {
        const formatService = { id, name, value: true }
        const filterService = servicesToSave.filter(service => service.id === id);
        const keys = Object.keys(servicesToSave);
        const keysEnabledIds = Object.keys(idServicesEnabled).includes(id.toString());
        if (filterService.length > 0) {
            keysEnabledIds && setEnabledIds([])
            let serviceNewArray = [...servicesToSave]
            const keyService = keys.filter(key => servicesToSave[key].id === id);
            serviceNewArray.splice(parseInt(keyService[0], 10), 1);
            setServicesToSave(serviceNewArray)
        } else {
            const newData = [...servicesToSave, formatService]
            setServicesToSave(newData);
            keysEnabledIds && setEnabledIds(idServicesEnabled[id])
        }
    }

    const changeInput = (e, id, name) => {
        const formatService = { id, name, value: e.target.value }
        const filterService = servicesToSave.filter(service => service.id === id);
        let newData = [];
        const keys = Object.keys(servicesToSave);
        if (filterService.length > 0) {
            let serviceNewArray = [...servicesToSave]
            const keyService = keys.filter(key => servicesToSave[key].id === id);
            let serviceToEdit = { ...serviceNewArray[keyService] };
            serviceToEdit.value = e.target.value
            serviceNewArray[keyService] = serviceToEdit
            setServicesToSave(serviceNewArray)
        } else {
            newData = [...servicesToSave, formatService]
            setServicesToSave(newData);
        }

        if (e.target.value === '') {
            let serviceNewArray = [...servicesToSave]
            const keyService = keys.filter(key => servicesToSave[key].id === id);
            serviceNewArray.splice(parseInt(keyService[0], 10), 1);
            setServicesToSave(serviceNewArray);
        }
    }

    const validations = () => {
        let countErrors = 0
        const embutidoXboca = servicesToSave.filter(service => service.id === servicesValidate.EMBUTIDO_BOCA.id)
        const instalacionONTTransceiver = servicesToSave.filter(service => service.id === servicesValidate.ONT_TRANSCEIVER.id)
        const decoUtp = servicesToSave.filter(service => service.id === servicesValidate.DECO_UTP.id)
        const instalacionBocaRed = servicesToSave.filter(service => service.id === servicesValidate.INSTALACION_BOCA_RED.id)

        if (embutidoXboca?.length > 0 && parseInt(embutidoXboca[0]?.value, 10) > servicesValidate.EMBUTIDO_BOCA.maxQuantity) {
            setAlertValidationEmbutido(`La cantidad en ${embutidoXboca[0]?.name} debe ser como máximo ${servicesValidate.EMBUTIDO_BOCA.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationEmbutido('')
        }
        if (decoUtp?.length > 0 && parseInt(decoUtp[0]?.value, 10) > servicesValidate.DECO_UTP.maxQuantity) {
            setAlertValidationDecoUtp(`La cantidad en ${decoUtp[0]?.name} debe ser como máximo ${servicesValidate.DECO_UTP.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationDecoUtp('')
        }
        if (instalacionONTTransceiver?.length > 0 && parseInt(instalacionONTTransceiver[0]?.value, 10) > servicesValidate.ONT_TRANSCEIVER.maxQuantity) {
            setAlertValidationInstalacionOnt(`La cantidad en ${instalacionONTTransceiver[0]?.name} debe ser como máximo ${servicesValidate.ONT_TRANSCEIVER.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationInstalacionOnt('')
        }
        if (instalacionBocaRed?.length > 0 && parseInt(instalacionBocaRed[0]?.value, 10) > servicesValidate.INSTALACION_BOCA_RED.maxQuantity) {
            setAlertValidationBocaRed(`La cantidad en ${instalacionBocaRed[0]?.name} debe ser como máximo ${servicesValidate.INSTALACION_BOCA_RED.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationBocaRed('')
        }
        return countErrors > 0 ? false : true;
    }

    const saveServices = () => {
        const validateResult = validations();
        if (validateResult) {
            let dataToSave = {
                services: servicesToSave,
            };
            nextStep(dataToSave)

            // update(ref(database, 'ordenes/' + idOt), dataToSave)
            //     .then(() => {
            //         setServicesToSave([]);
            //         window.location.href = `/materials/${idOt}`
            //     })
            //     .catch((e) => {
            //         console.log(e);
            //     });
        }
    }



    const orderService = (services) => {
        const newServices = services.sort((a, b) => a.order - b.order);
        setServices(newServices);
    }

    return (
        <Container component="main">
            <Typography component="h2" variant="h6" sx={{ color: '#565e5e', marginBottom: '20px' }}>
                Cuadrilla: <b>{data?.cuadrilla}</b>
            </Typography>
            {services?.length === 0 && <CircularProgress />}
            {services?.length > 0 && servicesToSave.length === 0 &&
                <MuiAlert severity="warning">Debe seleccionar al menos 1 servicio</MuiAlert>}

            <Box sx={{ display: 'flex', flexDirection: 'initial' }} className="box-container">
                <FormControl component="fieldset" variant="standard">
                    <FormGroup sx={{ flexDirection: 'initial' }}>
                        {services?.map(service => (
                            // service?.type === 'check' ?
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={service?.name}
                                        onChange={(e) => checkService(e, service?.id, service?.name)}
                                        disabled={enabledIds.length > 0 && !enabledIds.includes(service?.id)}
                                    />
                                }
                                label={service?.name}
                                className="label-check"
                                sx={{ flexBasis: '49%' }}
                                key={service?.name}
                            />
                            // :
                            // <TextField
                            //     id="outlined-number"
                            //     key={service?.name}
                            //     label={service?.name}
                            //     onWheel={(e) => e.target.blur()}
                            //     type="number"
                            //     className="text-form__service"
                            //     InputLabelProps={{
                            //         shrink: true,
                            //     }}
                            //     onChange={(e) => changeInput(e, service?.id, service?.name)}
                            //     sx={{ flexBasis: '49%', margin: '7px 5px 7px 0' }}
                            //     disabled={enabledIds.length > 0 && !enabledIds.includes(service?.id)}
                            // />
                        ))}
                    </FormGroup>
                    {alertValidationEmbutido !== '' && <MuiAlert severity="error">{alertValidationEmbutido}</MuiAlert>}
                    {alertValidationDecoUtp !== '' && <MuiAlert severity="error">{alertValidationDecoUtp}</MuiAlert>}
                    {alertValidationInstalacionOnt !== '' && <MuiAlert severity="error">{alertValidationInstalacionOnt}</MuiAlert>}
                    {alertValidationBocaRed !== '' && <MuiAlert severity="error">{alertValidationBocaRed}</MuiAlert>}
                </FormControl>
            </Box>
            <Button
                type="button"
                size="large"
                variant="contained"
                color="primary"
                className="button__save"
                onClick={saveServices}
                sx={{ margin: '15px 0', width: '50%' }}
            >
                Siguiente
            </Button>
        </Container>
    );
}

export default AddServices