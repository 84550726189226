import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, onChildAdded, query, orderByChild, startAfter, limitToLast, orderByValue, orderByKey, endAt } from "firebase/database";
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { StyledTableCell, StyledTableRow } from '../../utils/styled'


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SerialesSearch() {
    const [remitos, setRemitos] = useState([]);
    const [ordenes, setOrdenes] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [precioFilter, setPrecioFilter] = useState([]);
    const [searchTitle, setSearchTitle] = useState('');

    useEffect(() => {
        const date = new Date();
        const startDate = moment(date.setDate(date.getDate() - 90)).format("YYYYMMDD");
        onValue(ref(database, '/remitos/'), (snapshot) => {
            setRemitos(Object.values(snapshot.val()))
        }, {
            onlyOnce: true
        });
        // const searchQuery = query(ref(database, 'ordenes'), orderByChild('nroFormulario'), equalTo(nroFormulario));
 
        // onValue(searchQuery, (snapshot) => {
        //     const resultOrder = Object.values(snapshot.val()) || []
        //     setOrdenes(resultOrder)
        // }, {
        //     onlyOnce: true
        // });
        
        const orderQuery = query(
            ref(database, 'ordenes'),
            orderByChild('date'),
            startAfter(startDate),
        );
        onValue(orderQuery, (snapshot) => {
                const resultOrder = Object.values(snapshot.val()) || []
                setOrdenes(resultOrder)
            }, {
                onlyOnce: true
            });
        // onChildAdded(topUserPostsRef, (snapshot) => {
        //     console.log('snapshot.val()', snapshot.val());
        //     // ordersArray.push(snapshot.val())
        //     // setOrdenes(ordersArray)
        // }, {
        //     onlyOnce: true
        // });
        // const cuadrillaQuery = query(ref(database, 'ordenes2'), orderByChild('fecha'));
        // onValue(cuadrillaQuery, (snapshot) => {
        //     console.log('fech', snapshot.val());
        // }, {
        //     onlyOnce: true
        // });
    }, []);

    const handleClose = () => {
        setOpenDeleteModal(false)
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        // setSnackbarInfo({ text: '', type: '', open: false });
    };

    const searchFilter = () => {
        const newResult = []

        remitos.forEach(remito => {
            const serialFilter = remito.serials?.filter(serial => serial?.id?.toString() === searchValue)

            if (serialFilter?.length > 0) {
                const formatResult = {
                    id: remito.id,
                    fecha: remito.fecha,
                    cuadrillaEntrega: remito.cuadrillaEntrega,
                    cuadrillaReceptora: remito.cuadrillaReceptora,
                    tipoRemito: remito.tipoRemito,
                    serial: searchValue
                }
                newResult.push(formatResult)
            }
            setSearchResult(newResult)
        })
        
        ordenes.forEach(orden => {
            const orderFilterOnt = orden?.serialOnt?.filter(serial => serial === searchValue)
            const orderFilterFlow = orden?.serialesFlow?.filter(serial => serial === searchValue)
            const orderFilterOntR = orden?.ontRetirado?.filter(serial => serial === searchValue)
            const orderFilterFlowR = orden?.decoRetirado?.filter(serial => serial === searchValue)
            if (orderFilterOnt?.length > 0 || orderFilterFlow?.length > 0 || orderFilterOntR?.length > 0 || orderFilterFlowR?.length > 0) {
                const formatResult = {
                    id: orden.nroOt,
                    fecha: moment(orden.date).format("DD/MM/YYYY"),
                    cuadrillaEntrega: orden.cuadrilla,
                    cuadrillaReceptora: '-',
                    tipoRemito: orden.tipoOt,
                    serial: searchValue
                }
                newResult.push(formatResult)
            }
            setSearchResult(newResult)
        })
    }

    const onChangeSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const onKeyPress = (e) => {
        if (e.keyCode === 13) {
            searchFilter();
        }
    }


    const displayTable = searchTitle !== "" ? precioFilter : searchResult;

    return (
        <Container component="main" maxWidth="xl">
            <Typography variant="h5">Buscador de Seriales</Typography>
            <div className="filters-container">
                <Paper
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, marginBottom: '20px' }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar serial"
                        inputProps={{ 'aria-label': 'buscar' }}
                        onKeyDown={onKeyPress}
                        onChange={onChangeSearch}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={searchFilter}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </div>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID/Nº OT</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Nº Serial</StyledTableCell>
                            <StyledTableCell>Tipo</StyledTableCell>
                            <StyledTableCell>Almacén origen</StyledTableCell>
                            <StyledTableCell>Almacén destino</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchResult.length > 0 ? (
                            displayTable.map((remito, index) => (
                                <StyledTableRow
                                    key={index}
                                    sx={remitos?.deleted && { display: 'none' }}
                                >
                                    <StyledTableCell>{remito.id}</StyledTableCell>
                                    <StyledTableCell>{remito.fecha}</StyledTableCell>
                                    <StyledTableCell>{remito.serial}</StyledTableCell>
                                    <StyledTableCell>{remito.tipoRemito?.toUpperCase()}</StyledTableCell>
                                    <StyledTableCell>{remito.cuadrillaEntrega}</StyledTableCell>
                                    <StyledTableCell>{remito.cuadrillaReceptora}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={13} align="center">
                                    <Typography variant="h5">
                                        No se encontraron elementos
                                    </Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default SerialesSearch;
