import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { database } from "../../firebase";
import { ref, onValue, query, orderByChild, set, update, equalTo } from "firebase/database";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import logo from '../../cbalogo.jpeg';

function FacturaRemito({ dataRemito, isPreview }) {
    let { id } = useParams();

    const [factura, setFactura] = useState([]);

    useEffect(() => {
        if (isPreview) {
            setFactura(dataRemito);
        } else {
            const remitoQuery = query(ref(database, 'remitos'), orderByChild('id'), equalTo(parseInt(id, 10)));
            onValue(remitoQuery, (snapshot) => {
                setFactura(Object.values(snapshot.val())[0]);
            }, {
                onlyOnce: true
            });
        }
    }, []);

    const print = () => {
        var printContents = document.getElementById('printContent').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    }

    return (
        <Container component="main">
            <div id="printContent">
                {!isPreview &&
                    <div className="col-12 text-center">
                        <Button
                            variant="contained"
                            onClick={print}
                            endIcon={<PrintIcon />}
                            className="button-print"
                            sx={{ backgroundColor: 'black' }}
                        >
                            Imprimir
                        </Button>
                    </div>
                }

                <div className="factura__container">
                    <div className="header-section">
                        <div className="col-12">
                            <h4 className="title-remito">{factura.tipoRemito} DE MATERIALES</h4>
                        </div>
                    </div>
                    <div className="row section">
                        <div className="logo-header">
                            <img decoding="async" width="150" height="80" src={logo} className="elementor-animation-grow attachment-large size-large" alt="" loading="lazy" />
                        </div>
                        {!isPreview &&
                            <div className="col-4 details">
                                <span>
                                    Número #: 001 - {factura?.id?.toString().padStart(5, '0')}
                                </span>
                            </div>
                        }
                        <div className="col-6 data-client">
                            <span className="client">
                                <strong>
                                    Cuadrilla Origen: {' '}
                                </strong>
                                {factura.cuadrillaEntrega}
                                <br />
                                <br />
                                <strong>
                                    Cuadrilla Destino: {' '}
                                </strong>
                                {factura.cuadrillaReceptora}
                                <br />
                                <br />
                                <strong>
                                    Fecha:{' '}
                                </strong>
                                {factura.fecha}
                            </span>
                        </div>
                    </div>

                    <div className="invoicelist-body">
                        <table className="table-container">
                            <thead>
                                <tr>
                                    <th width="14%">PRODUCTO_ID</th>
                                    <th width="49%">NOMBRE</th>
                                    <th width="9%">CANTIDAD</th>
                                    <th width="24%">OBSERVACIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {factura?.controles?.map((control, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="14%">
                                                <span>{control.id}</span>
                                            </td>
                                            <td width="49%">
                                                <span>Control {control?.name.toUpperCase()}</span>
                                            </td>
                                            <td width="9%">
                                                <span>{control.value}</span>
                                            </td>
                                            <td width="24%">
                                                <span>{control.observaciones || ''}</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {factura?.materials?.map((prod, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="14%">
                                                <span>{prod.id}</span>
                                            </td>
                                            <td width="49%">
                                                <span>{prod.name}</span>
                                            </td>
                                            <td width="9%">
                                                <span>{prod.value}</span>
                                            </td>
                                            <td width="24%">
                                                <span>{prod.observaciones}</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="invoicelist-body">
                        <table className="table-container">
                            <caption className="comments">COMENTARIOS: {factura?.observaciones || ''}</caption>
                            <caption>EQUIPOS</caption>
                            <thead>
                                <tr>
                                    <th width="14%">TIPO</th>
                                    <th width="49%">NUMERO DE SERIE</th>
                                    <th width="34%">OBSERVACIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {factura?.serials?.map((serial, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="14%">
                                                <span>{serial?.tipo?.toUpperCase()}</span>
                                            </td>
                                            <td width="49%">
                                                <span>{serial.id}</span>
                                            </td>
                                            <td width="34%">
                                                <span>{serial.observaciones || ''}</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="invoicelist-footer">
                        <table>
                            {/* <tr className="taxrelated">
              <td>IVA:</td>
              <td id="total_tax"></td>
            </tr> */}
                            <tbody>
                                <tr>
                                    <td>
                                        <span>Firma:</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Aclaración:</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </Container>
    );
}

export default FacturaRemito
