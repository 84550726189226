import React, { useState, useEffect } from 'react';
import { equalTo, onValue, ref, query, orderByChild, startAfter, endBefore } from "firebase/database";
import { database } from "../../firebase";
import { Button, Container, Grid, Snackbar, TextField } from '@mui/material';
import { utils, writeFile } from 'xlsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { headingsTableGarantia } from '../../utils/default'
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GarantiasExcel({ index, serialCharge, onChangeTipoSerial, onChangeValues, observations }) {
    const [state, setState] = useState({
        dateTo: null,
        dateFrom: null,
    });
    const [alertValidationDate, setAlertValidationDate] = useState('')
    const [snackbarInfo, setSnackbarInfo] = useState({ open: false, text: '', type: 'neutral' })
    const [prices, setPrices] = useState([])

    useEffect(() => {
        onValue(ref(database, '/precios_new/'), (snapshot) => {
            setPrices(Object.values(snapshot.val()));
        }, {
            onlyOnce: true
        });
    }, []);

    const onChangeDateTo = (value) => {
        const d = new Date(value);
        const isBeforeDate = moment(d).isBefore(moment(new Date()));
        if (isBeforeDate) {
            setState(prevState => ({ ...prevState, dateTo: value }));
            setAlertValidationDate('')
        } else {
            setAlertValidationDate('La fecha debe ser menor o igual a la actual')
        }
    };

    const onChangeDateFrom = (value) => {
        const d = new Date(value);
        const isBeforeDate = moment(d).isBefore(moment(new Date()));
        if (isBeforeDate) {
            setState(prevState => ({ ...prevState, dateFrom: value }));
            setAlertValidationDate('')
        } else {
            setAlertValidationDate('La fecha debe ser menor o igual a la actual')
        }
    };

    const priceValueServices = (services, tipoCliente, tipoDia) => {
        let finalPrice = 0
        services?.map(service => {
            const typeValue = typeof service.value === 'boolean';
            // if (tipoCliente === 'HOME') {
                const priceHogar = tipoDia === 'Semana' ?
                parseInt(prices[service?.id]?.hogar, 10) :
                parseInt(prices[service?.id]?.feriado_domingo_hogar, 10);
                finalPrice += typeValue ? priceHogar : priceHogar * parseInt(service?.value, 10);
            // } else {
            //     const priceCorpo = tipoDia === 'Semana' ?
            //         parseInt(prices[service?.id]?.corporativo, 10) :
            //         parseInt(prices[service?.id]?.feriado_domingo_corporativo, 10);
            //     finalPrice += typeValue ? priceCorpo : priceCorpo * parseInt(service?.value, 10);
            // }
            return finalPrice
        })

        return finalPrice
    }

    const formatData = (orders) => {
        const newOrderFormat = []
        orders.map((order, index) => {
            const newOrder = {
                fecha1: moment(order[0]?.otRelacionada?.date).format("DD/MM/YYYY"),
                contrato1: order[0]?.otRelacionada?.contrato,
                nroOt1: order[0]?.otRelacionada?.nroOt,
                cliente1: order[0]?.otRelacionada?.cliente,
                cuadrilla1: order[0]?.otRelacionada?.cuadrilla,
                fecha2: moment(order[0]?.date).format("DD/MM/YYYY"),
                contrato2: order[0]?.contrato,
                nroOt2: order[0]?.nroOt,
                cliente2: order[0]?.cliente,
                cuadrilla2: order[0]?.cuadrilla,
                comments2: order[0]?.comments,
                total: priceValueServices(order[0]?.services, order[0]?.tipoCliente, order[0]?.tipoDia),
            }

            newOrderFormat.push(newOrder)
            return (
                newOrderFormat
            )
        })
        return newOrderFormat
    }

    const handleExport = (ordersArray) => {
        if (ordersArray?.length > 0) {
            const dateNow = moment(new Date()).format("YYYYMMDD")
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            const dataToExport = formatData(ordersArray)
            utils.sheet_add_aoa(ws, headingsTableGarantia);
            utils.sheet_add_json(ws, dataToExport, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Garantias');
            writeFile(wb, `garantias-${dateNow}.xlsx`);
        } else {
            setSnackbarInfo({ open: true, text: 'No hay datos para descargar con estos parámetros', type: 'error' })
        }
    }

    const downloadExcel = () => {
        let ordersArray = [];
        const dateFrom = moment(state.dateFrom, "DD/MM/YYYY").subtract(1, 'days').format("YYYYMMDD");
        const dateTo = moment(state.dateTo, "DD/MM/YYYY").add(1, 'days').format("YYYYMMDD");
        const topUserPostsRef = query(
            ref(database, 'garantias'),
            orderByChild('date'),
            startAfter(dateFrom),
            endBefore(dateTo)
        );
        onValue(topUserPostsRef, (snapshot) => {
            ordersArray = snapshot.val() ? Object.values(snapshot.val()) : [];
            handleExport(ordersArray)
        }, {
            onlyOnce: true
        });
        // setOpenModal(false)
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Grid item xs={12} sx={{ display: 'flex', marginBottom: '20px' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        className="date-selector"
                        label="Fecha Desde"
                        inputFormat="DD/MM/YYYY"
                        value={state.dateFrom}
                        onChange={onChangeDateFrom}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        className="date-selector-to"
                        label="Fecha Hasta"
                        inputFormat="DD/MM/YYYY"
                        value={state.dateTo}
                        onChange={onChangeDateTo}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            {alertValidationDate !== '' && <MuiAlert severity="error">{alertValidationDate}</MuiAlert>}

            <Button onClick={downloadExcel} variant="contained" disabled={state.dateFrom === null || state.dateTo === null}>
                Descargar planilla de garantias
            </Button>
            
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default GarantiasExcel;