import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { database } from "../../firebase";
import { onValue, ref } from "firebase/database";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import MuiAlert from '@mui/material/Alert';

export default function ModalFilters({ open, onCloseModal, items }) {
    const { search } = useLocation();
    const [cuadrillas, setCuadrillas] = useState([])
    const [state, setState] = useState({
        cuadrilla: '',
        cuadrillaReceptora: '',
        cuadrillaEntrega: '',
        nroFormulario: "",
        nroOt: "",
        contrato: "",
        dateTo: null,
        dateFrom: null,
        idRemito: '',
    });
    const [alertValidationDate, setAlertValidationDate] = useState('')

    useEffect(() => {
        onValue(ref(database, '/cuadrillas/'), (snapshot) => {
            setCuadrillas(Object.values(snapshot.val()))
        }, {
            onlyOnce: true
        });
    }, []);

    const createParams = () => {
        let param = search;
        if (state.cuadrilla !== '') {
            param = `?idCuadrilla=${state.cuadrilla}`
        }
        if (state.cuadrillaReceptora !== '') {
            param = `?cuadrillaReceptora=${state.cuadrillaReceptora}`
        }
        if (state.cuadrillaEntrega !== '') {
            param = `?cuadrillaEntrega=${state.cuadrillaEntrega}`
        }
        if (state.nroFormulario !== '') {
            param = `?nroFormulario=${state.nroFormulario}`
        }
        if (state.nroOt !== '') {
            param = `?nroOt=${state.nroOt}`
        }
        if (state.contrato !== '') {
            param = `?contrato=${state.contrato}`
        }
        if (state.idRemito !== '') {
            param = `?idRemito=${state.idRemito}`
        }
        if (state.dateFrom !== null && state.dateTo !== null) {
            const formatDateFrom = moment(state.dateFrom).format("YYYYMMDD");
            const formatDateTo = moment(state.dateTo).format("YYYYMMDD");
            param = param !== '' ? `${param}&dateFrom=${formatDateFrom}&dateTo=${formatDateTo}` : `?dateFrom=${formatDateFrom}&dateTo=${formatDateTo}`
        }
        return param;
    }

    const onAcceptFilter = () => {
        const completeParams = createParams();
        window.location.href = completeParams;
    }

    const onChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const clearFilters = () => {
        setState({
            cuadrilla: '',
            cuadrillaReceptora: '',
            cuadrillaEntrega: '',
            nroFormulario: '',
            nroOt: '',
            contrato: '',
            dateTo: null,
            dateFrom: null,
            idRemito: '',
        });
    }

    const onChangeSelectCuadrilla = (event, cuadrilla, name) => {
        const idCuadrilla = cuadrilla.id;
        setState(prevState => ({ ...prevState, [name]: idCuadrilla }));
    }

    const onChangeDateTo = (value) => {
        const d = new Date(value);
        const isBeforeDate = moment(d).isBefore(moment(new Date()));
        if (isBeforeDate) {
            setState(prevState => ({ ...prevState, dateTo: value }));
            setAlertValidationDate('')
        } else {
            setAlertValidationDate('La fecha debe ser menor o igual a la actual')
        }
    };

    const onChangeDateFrom = (value) => {
        const d = new Date(value);
        const isBeforeDate = moment(d).isBefore(moment(new Date()));
        if (isBeforeDate) {
            setState(prevState => ({ ...prevState, dateFrom: value }));
            setAlertValidationDate('')
        } else {
            setAlertValidationDate('La fecha debe ser menor o igual a la actual')
        }
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ingrese valor sobre lo que desea buscar"}
                </DialogTitle>
                <DialogContent>
                    {/* <span style={{ fontSize: '12px' }}>* Sólo se puede buscar por un único criterio</span> */}
                    {items.find(item => item === 'cuadrilla') &&
                        <FormControl sx={{ width: '100%', margin: '10px 0' }}>
                            <Autocomplete
                                options={cuadrillas}
                                id="disable-clearable"
                                disableClearable
                                onChange={(e, cuadrilla) => onChangeSelectCuadrilla(e, cuadrilla, 'cuadrilla')}
                                getOptionLabel={(option) => option.numero}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} label="Cuadrilla" placeholder="Cuadrilla" />
                                )}
                            />
                        </FormControl>
                    }
                    {items.find(item => item === 'cuadrillaEntrega') &&
                        <FormControl sx={{ width: '100%', margin: '10px 0' }}>
                            <Autocomplete
                                options={cuadrillas}
                                id="disable-clearable"
                                disableClearable
                                onChange={(e, cuadrilla) => onChangeSelectCuadrilla(e, cuadrilla, 'cuadrillaEntrega')}
                                getOptionLabel={(option) => option.numero}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} label="Cuadrilla Origen" placeholder="Cuadrilla Origen" />
                                )}
                            />
                        </FormControl>
                    }
                    {items.find(item => item === 'cuadrillaReceptora') &&
                        <FormControl sx={{ width: '100%', margin: '10px 0' }}>
                            <Autocomplete
                                options={cuadrillas}
                                id="disable-clearable"
                                disableClearable
                                onChange={(e, cuadrilla) => onChangeSelectCuadrilla(e, cuadrilla, 'cuadrillaReceptora')}
                                getOptionLabel={(option) => option.numero}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} label="Cuadrilla Destino" placeholder="Cuadrilla Destino" />
                                )}
                            />
                        </FormControl>
                    }
                    {items.find(item => item === 'nroFormulario') &&
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="default__textfield"
                            id="nroFormulario"
                            label="Nro Formulario"
                            value={state.nroFormulario}
                            name="nroFormulario"
                            onChange={onChangeValues}
                            sx={{ margin: '10px 0' }}
                        />
                    }
                    {items.find(item => item === 'nroOt') &&

                        <TextField
                            variant="outlined"
                            fullWidth
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="default__textfield"
                            id="nroOt"
                            label="Nro. OT"
                            value={state.nroOt}
                            name="nroOt"
                            onChange={onChangeValues}
                            sx={{ margin: '10px 0' }}
                        />
                    }
                    {items.find(item => item === 'contrato') &&
                        <TextField
                            variant="outlined"
                            fullWidth
                            className="default__textfield"
                            id="contrato"
                            label="Contrato"
                            value={state.contrato}
                            name="contrato"
                            onChange={onChangeValues}
                            sx={{ margin: '10px 0' }}
                        />
                    }
                    {items.find(item => item === 'idRemito') &&
                        <TextField
                            variant="outlined"
                            fullWidth
                            className="default__textfield"
                            id="idRemito"
                            label="ID Remito"
                            value={state.idRemito}
                            name="idRemito"
                            onChange={onChangeValues}
                            sx={{ margin: '10px 0' }}
                        />
                    }
                    {items.find(item => item === 'dateFrom') &&
                        <LocalizationProvider dateAdapter={AdapterMoment} sx={{ marginRight: '10px', width: '50%' }}>
                            <DatePicker
                                className="date-selector"
                                label="Fecha Desde"
                                inputFormat="DD/MM/YYYY"
                                value={state.dateFrom}
                                onChange={onChangeDateFrom}
                                renderInput={(params) => <TextField {...params} />}
                                sx={{ marginRight: '30px', width: '50%' }}
                            />
                        </LocalizationProvider>
                    }
                    {items.find(item => item === 'dateTo') &&
                        <LocalizationProvider dateAdapter={AdapterMoment} sx={{ marginLeft: '10px' }}>
                            <DatePicker
                                className="date-selector-to"
                                label="Fecha Hasta"
                                inputFormat="DD/MM/YYYY"
                                value={state.dateTo}
                                onError={(e, a) => console.log('error', e, a)}
                                onChange={onChangeDateTo}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    }
                    {alertValidationDate !== '' && <MuiAlert severity="error">{alertValidationDate}</MuiAlert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearFilters}>Limpiar Búsqueda</Button>
                    <Button onClick={onCloseModal}>Cancelar</Button>
                    <Button
                        onClick={onAcceptFilter}
                        title="Solo puedes buscar por un único criterio"
                        disabled={(state.dateFrom !== null && state.dateTo === null) || (state.dateFrom === null && state.dateTo !== null)}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}